<div class="header-left">
  <div class="welcome-header" data-cursor="-xl">
    <h1>
      <div>Hi!</div>
      <div>I'm <span class="text-accent">Dominik</span>,</div>
      <div>Web Developer</div>
    </h1>
    <p class="subheader" appSubheadingAnimations>Front End Developer</p>
  </div>
</div>
<div class="header-right">
  <canvas #canvas (mousemove)="setMousePosition($event)"></canvas>
</div>
