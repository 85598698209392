<div class="section-leading">
  <h2 appHeadingScroll>PROJECTS</h2>
  <p class="subtitle">
    Explore my latest creations, showcasing the essence of my work.
  </p>
</div>

<div class="block">
  <div class="project">
    <div
      class="project-img"
      data-cursor-text="View project"
      appMagneticElementToCursor
      [options]="projectImgOptions"
    >
      <a href="https://chat-35c45.web.app/" aria-label="go to sendM app">
        <img src="../../../assets/projects-img/sendM.webp" alt=""
      /></a>
    </div>
    <div class="project-detail">
      <h3>SendM</h3>
      <p>
        SendM is a communication platform leveraging advanced technologies such
        as WebSocket, Angular, and Server-Side Rendering, providing fast and
        secure real-time messaging. With instantaneous communication and a
        responsive user interface, users can easily and effectively communicate
        on both desktop and mobile devices.
      </p>
      <a href="https://chat-35c45.web.app/" aria-label="see live demo app"
        ><app-magnetic-link-button
          btnText="Live Demo"
          data-cursor="-opaque"
        ></app-magnetic-link-button
      ></a>
    </div>
  </div>

  <div class="tiles forward">
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>SSR</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
    </div>
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>SSR</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
    </div>
  </div>

  <div class="tiles backward">
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>SSR</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
    </div>
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>SSR</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
    </div>
  </div>
</div>

<div class="block">
  <div class="project reverse">
    <div
      class="project-img"
      data-cursor-text="View project"
      appMagneticElementToCursor
      [options]="projectImgOptions"
    >
      <a href="https://e-commerce-d31af.web.app/pl/" aria-label="go to e-commerce app">
        <img src="../../../assets/projects-img/cupofbean.webp" alt=""
      /></a>
    </div>
    <div class="project-detail">
      <h3>CupOfBean</h3>
      <p>
        My e-commerce platform, developed using Angular, provides users with a
        secure and intuitive shopping experience. Integrated with Stripe for
        payment processing, it offers seamless transactions, reliable order
        tracking, and effortless navigation.
      </p>
      <a href="https://e-commerce-d31af.web.app/" aria-label="see live demo e-commmerce-app"
        ><app-magnetic-link-button
          btnText="Live Demo"
          data-cursor="-opaque"
        ></app-magnetic-link-button
      ></a>
    </div>
  </div>

  <div class="tiles forward">
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>JEST</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Stripe Payment</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Tailwind</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
      <span>i18n</span>
    </div>
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>JEST</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Stripe Payment</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Tailwind</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
      <span>i18n</span>
    </div>
  </div>
  <div class="tiles backward">
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>JEST</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Stripe Payment</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Tailwind</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
      <span>i18n</span>
    </div>
    <div class="tiles-moving-bar">
      <span>Typescript</span>
      <span>NgRx</span>
      <span>Spring</span>
      <span>JEST</span>
      <span>WebSocket</span>
      <span>JWT</span>
      <span>Docker</span>
      <span>Angular</span>
      <span>Stripe Payment</span>
      <span>Sass</span>
      <span>Java</span>
      <span>Tailwind</span>
      <span>Hibernate</span>
      <span>Postgres</span>
      <span>Angular Material</span>
      <span>i18n</span>
    </div>
  </div>
</div>
<a class="github-ref" href="https://github.com/domino16"
  ><app-magnetic-link-button
    btnText="See more on GitHub"
    data-cursor="-opaque"
  ></app-magnetic-link-button
></a>
