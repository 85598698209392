<h2 appHeadingScroll>ABOUT ME</h2>
<div class="about-wrapper">
  <div appScrollImage class="img-container">
    <img src="../../../../assets/me (1).webp" alt="photo of me" />
  </div>
  <div appTextAnimation class="text">
    <p>
      Hello there! I'm Dominik, a passionate frontend developer specializing in
      Angular. Although I don't have extensive professional experience yet, my
      determination and eagerness to learn allow me to continuously grow and
      refine my skills.
    </p>
    <p>
      My journey into programming began with a fascination for the interactivity
      and functionality of websites. Since then, I've been deeply immersed in
      the frontend world, uncovering its endless possibilities. Along the way,
      I've also gained foundational skills in crafting simple backends using
      Spring, enhancing my understanding of the entire web application
      ecosystem.
    </p>
    <p>
      I'm ready to tackle the challenges that come with working in the IT
      industry. My passion is not only to create aesthetically pleasing and
      functional projects but also to continuously strive for excellence and
      expand my horizons. If you're looking for someone who approaches frontend
      projects with passion and can handle basic backend tasks, I'm more than
      willing to take on the challenge!
    </p>
  </div>
</div>
