<div class="divide-line"></div>
<ul>
  <li (click)="scrollTo('app-intro')">
    <div class="single-word">
      <div class="single-letter">H</div>
      <div class="single-letter">O</div>
      <div class="single-letter">M</div>
      <div class="single-letter">E</div>
    </div>
    <div class="single-word">
      <div class="single-letter">H</div>
      <div class="single-letter">O</div>
      <div class="single-letter">M</div>
      <div class="single-letter">E</div>
    </div>
  </li>
  <li (click)="scrollTo('app-about')">
    <div class="single-word">
      <div class="single-letter">A</div>
      <div class="single-letter">B</div>
      <div class="single-letter">O</div>
      <div class="single-letter">U</div>
      <div class="single-letter">T</div>
    </div>
    <div class="single-word">
      <div class="single-letter">A</div>
      <div class="single-letter">B</div>
      <div class="single-letter">O</div>
      <div class="single-letter">U</div>
      <div class="single-letter">T</div>
    </div>
  </li>
  <li (click)="scrollTo('app-skills')">
    <div class="single-word">
      <div class="single-letter">S</div>
      <div class="single-letter">K</div>
      <div class="single-letter">I</div>
      <div class="single-letter">L</div>
      <div class="single-letter">L</div>
      <div class="single-letter">S</div>
    </div>
    <div class="single-word">
      <div class="single-letter">S</div>
      <div class="single-letter">K</div>
      <div class="single-letter">I</div>
      <div class="single-letter">L</div>
      <div class="single-letter">L</div>
      <div class="single-letter">S</div>
    </div>
  </li>
  <li (click)="scrollTo('app-projects')">
    <div class="single-word">
      <div class="single-letter">P</div>
      <div class="single-letter">R</div>
      <div class="single-letter">O</div>
      <div class="single-letter">J</div>
      <div class="single-letter">E</div>
      <div class="single-letter">C</div>
      <div class="single-letter">T</div>
      <div class="single-letter">S</div>
    </div>
    <div class="single-word">
      <div class="single-letter">P</div>
      <div class="single-letter">R</div>
      <div class="single-letter">O</div>
      <div class="single-letter">J</div>
      <div class="single-letter">E</div>
      <div class="single-letter">C</div>
      <div class="single-letter">T</div>
      <div class="single-letter">S</div>
    </div>
  </li>
  <li (click)="scrollTo('app-contact-section')">
    <div class="single-word">
      <div class="single-letter">C</div>
      <div class="single-letter">O</div>
      <div class="single-letter">N</div>
      <div class="single-letter">T</div>
      <div class="single-letter">A</div>
      <div class="single-letter">C</div>
      <div class="single-letter">T</div>
    </div>
    <div class="single-word">
      <div class="single-letter">C</div>
      <div class="single-letter">O</div>
      <div class="single-letter">N</div>
      <div class="single-letter">T</div>
      <div class="single-letter">A</div>
      <div class="single-letter">C</div>
      <div class="single-letter">T</div>
    </div>
  </li>
</ul>
