@if((isMenuOpen$ | async)){
<div class="transition" @slideFirstDiv></div>
<div class="transition" @slideSecondDiv></div>
} @if((isPreLoaded | async) === false){
<app-loader @openDelay></app-loader>
}@else {
<div class="transition" @slideFirstDiv></div>
<div class="transition" @slideSecondDiv></div>
}

<app-sidebar></app-sidebar> @if (isMenuOpen$ | async){<app-menu
  @openDelay
></app-menu
>}

@if(!isMobile){
  <app-background-smoke></app-background-smoke>
}

<div class="wrapper">
  <router-outlet />
  <app-footer></app-footer>
</div>
<div appMagneticCursor class="cb-cursor" #cursor>
  <div class="cb-cursor-text" #cursorText></div>
</div>
