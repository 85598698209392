<div class="section-leading">
  <h2 appHeadingScroll>MY SKILLS</h2>
  <p class="subtitle">Technologies I've been working recently</p>
</div>

<div class="tiles forward">
  <div class="tiles-moving-bar">
    <div class="tile" data-cursor-text="Angular">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Angular_full_color_logo.svg"
          alt="angular logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Git">
      <svg>
        <image xlink:href="../../../../assets/logos/Git_icon.svg" alt="git
        logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="GSAP">
      <svg>
        <image
          xlink:href="../../../../assets/logos/gsap-greensock.svg"
          alt="gsap logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="HTML5">
      <svg>
        <image
          xlink:href="../../../../assets/logos/html5.svg"
          alt="html5 logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Java">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Java_Logo.svg"
          alt="java logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Javascript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/javascript.svg"
          alt="javascript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Postgres">
      <svg>
        <image
          xlink:href="../../../../assets/logos/postgres.svg"
          alt="postgres logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Sass">
      <svg>
        <image xlink:href="../../../../assets/logos/sass.svg" alt="sass logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Spring">
      <svg>
        <image
          xlink:href="../../../../assets/logos/spring.svg"
          alt="spring logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Tailwind">
      <svg>
        <image
          xlink:href="../../../../assets/logos/tailwind.svg"
          alt="tailwind logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Typescript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/typescript.svg"
          alt="typescript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Docker">
      <svg>
        <image
          xlink:href="../../../../assets/logos/docker-svgrepo-com.svg"
          alt="docker logo"
        />
      </svg>
    </div>
  </div>
  <div class="tiles-moving-bar">
    <div class="tile" data-cursor-text="Angular">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Angular_full_color_logo.svg"
          alt="angular logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Git">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Git_icon.svg"
          alt="git logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="GSAP">
      <svg>
        <image
          xlink:href="../../../../assets/logos/gsap-greensock.svg"
          alt="gsap logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="HTML5">
      <svg>
        <image
          xlink:href="../../../../assets/logos/html5.svg"
          alt="html5 logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Java">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Java_Logo.svg"
          alt="java logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Javascript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/javascript.svg"
          alt="javascript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Postgres">
      <svg>
        <image
          xlink:href="../../../../assets/logos/postgres.svg"
          alt="postgres logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Sass">
      <svg>
        <image xlink:href="../../../../assets/logos/sass.svg" alt="sass logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Spring">
      <svg>
        <image
          xlink:href="../../../../assets/logos/spring.svg"
          alt="spring logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Tailwind">
      <svg>
        <image
          xlink:href="../../../../assets/logos/tailwind.svg"
          alt="tailwind logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Typescript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/typescript.svg"
          alt="typescript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Docker">
      <svg>
        <image
          xlink:href="../../../../assets/logos/docker-svgrepo-com.svg"
          alt="docker logo"
        />
      </svg>
    </div>
  </div>
</div>
<div class="tiles backward">
  <div class="tiles-moving-bar">
    <div class="tile" data-cursor-text="Angular">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Angular_full_color_logo.svg"
          alt="angular logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Git">
      <svg>
        <image xlink:href="../../../../assets/logos/Git_icon.svg" alt="git
        logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="GSAP">
      <svg>
        <image
          xlink:href="../../../../assets/logos/gsap-greensock.svg"
          alt="gsap logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="HTML5">
      <svg>
        <image
          xlink:href="../../../../assets/logos/html5.svg"
          alt="html5 logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Java">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Java_Logo.svg"
          alt="java logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Javascript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/javascript.svg"
          alt="javascript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Postgres">
      <svg>
        <image
          xlink:href="../../../../assets/logos/postgres.svg"
          alt="postgres logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Sass">
      <svg>
        <image xlink:href="../../../../assets/logos/sass.svg" alt="sass logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Spring">
      <svg>
        <image
          xlink:href="../../../../assets/logos/spring.svg"
          alt="spring logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Tailwind">
      <svg>
        <image
          xlink:href="../../../../assets/logos/tailwind.svg"
          alt="tailwind logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Typescript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/typescript.svg"
          alt="typescript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Docker">
      <svg>
        <image
          xlink:href="../../../../assets/logos/docker-svgrepo-com.svg"
          alt="docker logo"
        />
      </svg>
    </div>
  </div>
  <div class="tiles-moving-bar">
    <div class="tile" data-cursor-text="Angular">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Angular_full_color_logo.svg"
          alt="angular logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Git">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Git_icon.svg"
          alt="git logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="GSAP">
      <svg>
        <image
          xlink:href="../../../../assets/logos/gsap-greensock.svg"
          alt="gsap logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="HTML5">
      <svg>
        <image
          xlink:href="../../../../assets/logos/html5.svg"
          alt="html5 logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Java">
      <svg>
        <image
          xlink:href="../../../../assets/logos/Java_Logo.svg"
          alt="java logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Javascript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/javascript.svg"
          alt="javascript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Postgres">
      <svg>
        <image
          xlink:href="../../../../assets/logos/postgres.svg"
          alt="postgres logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Sass">
      <svg>
        <image xlink:href="../../../../assets/logos/sass.svg" alt="sass logo" />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Spring">
      <svg>
        <image
          xlink:href="../../../../assets/logos/spring.svg"
          alt="spring logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Tailwind">
      <svg>
        <image
          xlink:href="../../../../assets/logos/tailwind.svg"
          alt="tailwind logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Typescript">
      <svg>
        <image
          xlink:href="../../../../assets/logos/typescript.svg"
          alt="typescript logo"
        />
      </svg>
    </div>
    <div class="tile" data-cursor-text="Docker">
      <svg>
        <image
          xlink:href="../../../../assets/logos/docker-svgrepo-com.svg"
          alt="docker logo"
        />
      </svg>
    </div>
  </div>
</div>
